.sectionheader {
	font-size: 250%;
	text-align: center;
}
@media screen and (max-width: 1000px) {
	.sectionheader {
		position: relative;
		top: 6vh;
		font-size: 400%;
	}
}
.ProjectSelector {
	position: absolute;
	height: 30vh;
	width: 6vw;
	top: calc(50% - 15vh);
	z-index: 1;
}
@media screen and (max-width: 1000px) {
	.ProjectSelector {
		height: 17vh;
		top: calc(50% - (17vh / 2));
	}
}
.Project {
	display: none;
	position: absolute;
	width: 100vw;
	height: 100%;
}

.ProjectOverview {
	width: 80%;
	margin-top: 3%;
	margin-left: 10%;
}
.OverviewImage {
	height: 40vh;
	width: 40vw;
}
@media screen and (max-width: 1000px) {
	.OverviewImage {
		position: relative;
		top: 23vh;
		width: 40vw;
		height: 15vh;
	}
}
.compatibility {
	width: 40vw;
	text-align: center;
	font-size: 200%;
}
@media screen and (max-width: 1000px) {
	.compatibility {
		position: relative;
		top: 23vh;
	}
}
.compatibileDevice {
	height: 5vh;
}
.OverviewText {
	position: absolute;
	left: 55vw;
	top: 13vh;
	font-size: 200%;
	width: 20vw;
	height: 70vh;
	overflow-y: auto;
	overflow-x: hidden;
	padding-right: 5px;
}
.OverviewTextBody {
	position: relative;
}
@media screen and (max-width: 1000px) {
	.OverviewText {
		font-size: 220%;
		width: 30vw;
	}
}
.Links {
	position: relative;
	top: 0;
	left: calc(50% - 5vw);
}
.ProjectInteraction {
	position: absolute;
	/* Style the text inside of the buttons */
	background-color: #2a6e9d;
	color: #dadee7;
	text-decoration: none;
	/* Center the text inside of the buttons */
	display: flex;
	justify-content: center;
	align-items: center;
	/* Style the buttons*/
	width: 10vw;
	height: 5vh;
	cursor: pointer;
	/* Round the edges */
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
}
.ProjectInteraction:hover,
.ProjectInteraction:focus {
	background-color: #175385;
	box-shadow: -10px -10px 8px #505d7c;
	-moz-box-shadow: -4px -4px 8px #505d7c;
	-webkit-box-shadow: -4px -4px 8px #505d7c;
}
@media screen and (max-width: 1000px) {
	.Links {
		left: calc(50% - 10vw);
	}
	.ProjectInteraction {
		width: 20vw;
	}
}
.Link {
	top: 6vh;
}
.Code {
	top: 12vh;
}
@media screen and (max-width: 1000px) {
	.Link {
		top: 5.5vh;
	}
	.Code {
		top: 11vh;
	}
}

.ProjectPopup {
	visibility: hidden;
	position: fixed;
	background-color: #c3ddfd;
	top: 8vh;
	left: 8vw;
	height: 86.4vh;
	width: 80vw;
	z-index: 3;
	/* Round the edges */
	border-radius: 15px;
	-moz-border-radius: 15px;
	-webkit-border-radius: 15px;
}
@media screen and (max-width: 1000px) {
	.ProjectPopup {
		left: 5vw;
		width: 90vw;
	}
}
.close {
	position: absolute;
	right: 2%;
	top: 2%;
	font-size: 500%;
	color: gray;
	cursor: pointer;
}
@media screen and (max-width: 1000px) {
	.close {
		font-size: 1000%;
	}
}
.ImageBlock {
	display: block;
	width: 36.5vw;
	height: 50vh;
	padding-left: 1vw;
	padding-top: 3vh;
}
@media screen and (max-width: 1000px) {
	.ImageBlock {
		position: relative;
		left: 10vw;
		width: 70vw;
		height: 30vh;
	}
}
.ImageBar {
	width: 100%;
	height: 20%;
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	/* Not auto to keep image sizes constant */
	/* Images resized and scrollbar hidden when needed */
	overflow-x: auto;
	overflow-y: hidden;
}
.sampleimage {
	height: calc(100% - 0.6vw - 2px);
	margin: 1px;
	background: white;
	cursor: pointer;
}
.selectedimage {
	border-style: solid;
	border-width: 0.3vw;
	border-color: black;
	cursor: default;
}
.activeimage {
	width: 100%;
	height: 80%;
}

@media screen and (max-width: 1000px) {
	.Link {
		top: 5.5vh;
	}
	.Code {
		top: 11vh;
	}
}
.largeDescription {
	margin-bottom: 1rem;
}
.description {
	position: absolute;
	font-size: 200%;
	width: 30%;
	right: 15%;
	top: -4vh;
}
@media screen and (max-width: 1000px) {
	.descriptionheader {
		font-size: 175%;
		position: relative;
		top: 0%;
	}
	.description {
		font-size: 225%;
		position: relative;
		top: 0%;
		left: 10%;
		width: 80%;
	}
}
.techstack {
	font-size: 150%;
}
@media screen and (max-width: 1000px) {
	.techstack {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
	.techstackHeader {
		top: 0;
	}
}
.tools {
	font-size: 150%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	text-align: center;
}
.ToolLogo {
	height: 100px;
}
.Indicator {
	height: 1.5vh;
	width: 1.5vh;
	background-color: black;
	margin: 2px;
	opacity: 0.5;

	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-o-border-radius: 50%;
}
.IndicatorSelected {
	opacity: 1;
}

/* Code for Escape Room extension */
/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 13px;
	width: 13px;
	left: 4px;
	bottom: 5.5px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #2196f3;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(39px);
	-ms-transform: translateX(39px);
	transform: translateX(39px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

#escapeRoomToggle {
	width: 100%;
	font-size: 200%;
	position: absolute;
	text-align: center;
	left: 0%;
	top: 2%;
}
/* End of code for Escape Room extension */

#ProjectBlock {
	background-color: #8895b3;
	background: linear-gradient(115deg, #2d3c6262 0%, #8895b3 100%);
	min-height: calc(100vh - 1.5em * 4);
	height: auto;
}
#ProjectHeading {
	padding-top: 2vh;
}
#MoveToPreviousProject {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	left: 1rem;
}
#MoveToNextProject {
	left: calc(100vw - 6vw - 2rem); /* Not right in order to prevent movement when popup opens */
}

#Projects {
	height: calc(90% - 2vh - 1.5em);
	position: absolute;
	width: 100vw;
	overflow-x: hidden; /* Prevents issues in animation when rendering on mobile */
}
/* Moves project block right and left */
@keyframes Slide_Right_Out {
	0% {
		left: 0vw;
	}
	25% {
		left: 30vw;
	}
	50% {
		left: 60vw;
	}
	75% {
		left: 80vw;
	}
	100% {
		left: 100vw;
	}
}
@keyframes Slide_Right_In {
	0% {
		left: -100vw;
	}
	25% {
		left: -70vw;
	}
	50% {
		left: -40vw;
	}
	75% {
		left: -20vw;
	}
	100% {
		left: 0vw;
	}
}
@keyframes Slide_Left_Out {
	0% {
		left: 0vw;
	}
	25% {
		left: -30vw;
	}
	50% {
		left: -60vw;
	}
	75% {
		left: -80vw;
	}
	100% {
		left: -100vw;
	}
}
@keyframes Slide_Left_In {
	0% {
		left: 100vw;
	}
	25% {
		left: 70vw;
	}
	50% {
		left: 40vw;
	}
	75% {
		left: 20vw;
	}
	100% {
		left: 0vw;
	}
}

.MoveLeftOut {
	display: block; /* Display the project while it is moving */
	animation-name: Slide_Left_Out;
	animation-duration: 700ms;
	animation-timing-function: linear;
}
.MoveLeftIn {
	display: block; /* Display the project while it is moving */
	animation-name: Slide_Left_In;
	animation-duration: 700ms;
	animation-timing-function: linear;
}
.MoveRightOut {
	display: block; /* Display the project while it is moving */
	animation-name: Slide_Right_Out;
	animation-duration: 700ms;
	animation-timing-function: linear;
}
.MoveRightIn {
	display: block; /* Display the project while it is moving */
	animation-name: Slide_Right_In;
	animation-duration: 700ms;
	animation-timing-function: linear;
}

/* This is the id and classes used to make the small dots that indicate what project the user is viewing*/
#ProjectIndicator {
	position: absolute;
	left: 40%;
	width: 20%;
	bottom: 0%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}
